.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-width: 599px) {
  #main-container{
      width: 90%;
  }
}
@media only screen and (min-width: 600px) {
  #main-container{
      width: 80%;
  }
}
@media only screen and (min-width: 1000px) {
  #main-container{
      width: 70%;
  }
}
@media only screen and (min-width: 1400px) {
  #main-container{
      width: 60%;
  }
}
@media only screen and (min-width: 1800px) {
  #main-container{
      width: 50%;
  }
}
body {
  background: rgb(0,0,0);
}
#main-container{
  margin: auto;
  text-align: start;
}
.u-s-page-header{
  font-family: "Helvetica";
  font-size: 3em;
  text-align: center;
}
.u-s-main-title{
  font-family: "Helvetica";
  font-size: 2em;
}
input{
  background-color: rgb(232, 143, 16);
}
input:hover{
  background-color: rgb(185, 107, 5);
}
.page-header{
  font-family: "Helvetica";
  font-size: 3em;
  color: black;
  text-align: center;
}
.page-header span{
  background-color: orange;
  border-radius: 8px;
  padding: 8px 8px 0 8px;
}
.main-title{
  font-family: "Helvetica";
  font-size: 2em;
  color: white;
}
.article{
  font-family: "Arial", "Courier New", monospace;
  font-size: 1em;
  line-height: 2em;
  color: white;
}
label{
  font-weight: bold;
}
input[type=text],input[type=password], select, textarea, #email {
  width: 100%;
  padding: 12px;
  border: 1px solid black;
  border-radius: 4px; 
  box-sizing: border-box;
  margin-top: 6px; 
  margin-bottom: 16px; 
  resize: vertical
}
input[type=submit] {
  background-color: #333;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type=submit]:hover {
  background-color: #888;
}
.container {
  border-radius: 8px;
  background-color: rgb(232, 190, 0);
  padding: 1em;
}
#c-content{
  height: 200px;
}
#nav-bar{
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 48px;
  display: inline-block;
}
#nav-bar-container{
  text-align: center;
}
#nav-bar li{
  display: inline-block;
  margin: 0 16px 0 16px;
  list-style: none;
}
.nav-bar-icon{
  width:64px;
  padding: 2px;
  border-radius: 4px;
  background-color: white;
}
.active{
  padding: 58px 6px 2px 6px;
  border-radius: 8px;
  border-style: solid;
  border-color: orange;
  border-width: 2px;
}
