.submenu {
    background-color: #444444;
    font-weight: bold;
    display:inline;
    padding: 3px;
    border-radius: 4px;   
  }

  .button-link {
    display: inline-block;
    padding: 0px 4px;
    font-size: 16px;
    color: black;
    background-color: orange; /* Button color */
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.button-link:hover {
    background-color: white; /* Darker shade on hover */
}
#i-d-quick-nav{
  border-left: 2px solid #444444;
  position: fixed;
  top:320px;
  float: right;
}
#i-d-quick-nav li{
  padding: 4px;
  color: rgb(255, 255, 255);
}
#i-d-quick-nav li a:hover{
  background-color: #444444;
}
#i-d-quick-nav li a{
  text-decoration:none;
}
#i-d-quick-nav li a:visited{
  color: inherit;
}
@media only screen and (max-width: 1399px) {
  #i-d-quick-nav{
      visibility: hidden;
  }
}
@media only screen and (min-width: 1400px) {
  #i-d-quick-nav{
      right: 32px;
  }
}
@media only screen and (min-width: 1800px) {
  #i-d-quick-nav{
      right: 64px;
  }
}
  @media only screen and (max-width: 1199px) {
    #i-d-check{
        width: 648px;
        height: 323px;
    }
}
@media only screen and (min-width: 1200px) {
    #i-d-check{
        width: 864px;
        height: 431px;
    }
}
